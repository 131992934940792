<!--  -->
<template>
    <div>
        <div class="center">
            <p>联系方式</p>
            <p>QQ邮箱:1354352923@qq.com</p>
            <p>电话:15797784918</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },

    methods: {},

    components: {},
};
</script>
<style lang="less" scoped>
.center {
    width: 80%;
    height: 100vh;
    background-color: #333;
    margin: 1px auto;
    overflow: hidden;
    p {
        font-size: 40px;
        color: #fff;
    }
}
</style>
